import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const UpdateAssetsAllocation = async (userData,id) => {
  try {
    const response = await api.patch(
      `/assetsallocation/asset-allocation/${userData.id}`,
      JSON.stringify(userData), // Ensure it's a JSON string
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    

    return response.data;
  } catch (error) {
    console.error("API Error:", error.response?.data || error.message);
    throw error;
  }
};
