import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import { Userlist } from '../Settings/API/User/UserList';
import "datatables.net-bs5";


const FinancialAdviserUserList = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await Userlist();
                if (response?.results) {
                    setUsers(response.results);
    
                    setTimeout(() => {
                        if ($.fn.DataTable.isDataTable("#userTable")) {
                            $("#userTable").DataTable().destroy(); // Destroy old instance
                        }
                        $("#userTable").DataTable(); // Initialize DataTable
                    }, 100);
                    setLoading(false);
                }
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        };
    
        fetchUsers();
    
        // Initialize Bootstrap tooltips
        setTimeout(() => {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltipTriggerList.forEach((tooltipEl) => new bootstrap.Tooltip(tooltipEl));
        }, 500);
    }, []);
    if (loading) {
        return (
          <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        );
      }
    return (
        <div className="container mt-4 vh-100">
            <h2 className="mb-4"><i className="fa fa-users me-2"></i> User List</h2>

            <div className="table-responsive">
                <table id="userTable" className="table table-striped table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <th>#</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Phone</th>
                            <th className="text-center">Nationality</th>
                            <th className="text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length > 0 ? (
                            users.map((user, index) => (
                                <tr key={user.id}>
                                    {/* Index */}
                                    <td className="text-center">{index + 1}</td>

                                    {/* Profile Image, Name & Email in One Column */}
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <img
                                                src={user.profile_image ? `${process.env.REACT_APP_API_URL}/${user.profile_image}` : "/profileimg.png"}
                                                alt="Profile"
                                                className="rounded-circle border"
                                                width="40"
                                                height="40"
                                                style={{ marginRight: "10px" }}
                                            />
                                            <div>
                                                <strong>{user.first_name} {user.last_name}</strong>
                                                <br />
                                                <small className="text-muted">{user.email}</small>
                                            </div>
                                        </div>
                                    </td>

                                    {/* Other User Info */}
                                    <td className="text-center">{user.phone_number}</td>
                                    <td className="text-center">{user.nationality}</td>

                                    {/* Action Buttons */}
                                    <td className="text-center">
                                        {/* View Profile Link */}
                                        <Link 
                                            className="btn btn-primary btn-sm me-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="View Profile"
                                            to={`/financial-adviser/user/${user.id}`} // Corrected template string usage
                                        >
                                            <i className="fa fa-user"></i> View
                                        </Link>

                                        {/* View Risk Questionnaire Response Link */}
                                        <Link
                                            className="btn btn-primary btn-sm me-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="View Risk Questionnaire Response"
                                            to={`/financial-adviser/user/${user.id}/risk-questionnaire`} // Assuming this is the correct route
                                        >
                                            <i className="fa fa-file"></i> View
                                        </Link>
                                        {/* Asset Allocation */}
                                        <Link
                                            className="btn btn-primary btn-sm me-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="View Asset Allocation"
                                            to={`/financial-adviser/asset/${user.id}/allocation`} // Assuming this is the correct route
                                        >
                                            <i className="fa fa-map-signs"></i> View
                                        </Link>
                                    </td>


                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center p-3">
                                    <strong>No users found.</strong>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>
        </div>
    );
};

export default FinancialAdviserUserList;