import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { RiskAssesment } from "../../Settings/API/RiskAssesment/Riskassesment";

const QuestionAndAnswerForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [question, setQuestion] = useState(null);
  const [hint, setHint] = useState("");
  const [explanation, setExplanation] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [answer, setAnswer] = useState("");
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);  // <-- Prevent duplicate calls

  // Extract user_id and token from URL
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id");
  const token = queryParams.get("token");

  // Function to call RiskAssessment API
  const callApiRiskAssesment = async (input) => {
    try {
      setLoading(true);
      const response = await RiskAssesment(userId, input, token);
      
      if (response.status === "success" && response.status_code === 200 && response.message === "Assesment Complete") {
        navigate(`/steps?step=3&user_id=${userId}&token=${token}`, { state: { result: response.results } });
        return;
      }
      
      if (response.status === "success" && response.status_code === 200) {
        const result = response.results[0];
        const questionData = result.initial_question || result.next_question;

        setQuestion(questionData?.question || "No question available");
        setHint(questionData?.hint || "");
        setExplanation(result.explanation || "");
        setOptions(questionData?.options || null);  // ✅ Update options
        setAnswer("");  // ✅ Reset answer when fetching a new question
  

        // try {
        //   const audioResponse = JSON.parse(result.audio_response);
        //   if (audioResponse.status === "success" && audioResponse.data.audio_file) {
        //     setAudioUrl(audioResponse.data.audio_file);
        //   }
        // } catch (error) {
        //   console.error("Error parsing audio response:", error);
        // }
      } else {
        setError("Failed to retrieve question.");
      }
    } catch (err) {
      console.error("Error calling API:", err);
      setError("An error occurred while calling the API.");
    } finally {
      setLoading(false);
    }
  };

  // Automatically call API when component loads (but only once)
  useEffect(() => {
    if (!hasFetched) {
      callApiRiskAssesment("how are you");
      setHasFetched(true);
    }
  }, [hasFetched]);

  // Handle answer submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!answer.trim()) return;
    const selectedAnswerText = options ? options[answer] : answer;
    callApiRiskAssesment(selectedAnswerText);
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="card shadow-lg mt-3">
        <div className="card-body">
          <h3 className="card-title text-center mb-4">Question's</h3>

          {loading && <p>Loading question...</p>}
          {error && <div className="alert alert-danger">{error}</div>}
          {!loading && !error && question && (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label"><strong>Question:</strong> {question}</label>
                {hint && <p className="text-muted"><em>Hint: {hint}</em></p>}
              </div>
              {options ? (
                <div className="mb-3">
                  {Object.entries(options).map(([key, value]) => (
                    <div className="form-check" key={key}>
                      <input
                        type="radio"
                        className="form-check-input"
                        id={`option-${key}`}
                        name="answer"
                        value={key}
                        checked={answer === key}
                        onChange={(e) => setAnswer(e.target.value)}
                        required
                      />
                      <label className="form-check-label" htmlFor={`option-${key}`}>{value}</label>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    required
                  />
                </div>
              )}
              {audioUrl && (
                <div className="mb-3">
                  <audio controls>
                    <source src={audioUrl} type="audio/wav" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
              )}
              {explanation && (
                <div className="alert alert-info mt-3">
                  <strong>Explanation:</strong> <br />
                  {explanation}
                </div>
              )}
              <button type="submit" className="btn btn-primary" disabled={loading}>
                Submit Answer
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionAndAnswerForm;
