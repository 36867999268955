import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import FinancialAdviserDashboard from '../Dashboard';
import FinancialAdviserHeader from '../includes/header';
import FinancialAdviserFooter from '../includes/footer';
import FinancialAdviserLogout from '../Auth/FinancialAdviserLogout';
import FinancialAdviserUserList from '../FinancialAdviserUserList';
import FinancialAdviserUserProfile from '../FinancialAdviserUserProfile';
import FinancialAdviserUserRiskQuestionnaire from '../FinancialAdviserUserRiskQuestionnaire';
import FinancialAdviserAssetAllocation from '../FinancialAdviserAssetAllocation';

const FinancialAdviserRoute = () => {
  const [loading, setLoading] = useState(true);
  const [isValidUser, setIsValidUser] = useState(false);

  useEffect(() => {
    setLoading(true); // ✅ Set loading before authentication check

    try {
      const storedData = localStorage.getItem('AuthFinancialAdviser');
      const isAuthenticated = localStorage.getItem('isAuthenticatedFinancialAdviser') === 'true';

      if (storedData && isAuthenticated) {
        setIsValidUser(true);
      } else {
        localStorage.removeItem('AuthFinancialAdviser');
        localStorage.removeItem('isAuthenticatedFinancialAdviser');
      }
    } catch (error) {
      console.error('Authentication check failed:', error);
    } finally {
      setTimeout(() => setLoading(false), 100); // ✅ Ensure smooth transition
    }
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return isValidUser ? (
    <>
      <FinancialAdviserHeader />
      <Routes>
        <Route path="/" element={<FinancialAdviserDashboard />} />
        <Route path="/*" element={<FinancialAdviserDashboard />} />
        <Route path="/userlist" element={<FinancialAdviserUserList />} />
        <Route path="/userlist" element={<FinancialAdviserUserList />} />
        <Route path="/user/:id" element={<FinancialAdviserUserProfile />} />
        <Route path="/user/:id/risk-questionnaire" element={<FinancialAdviserUserRiskQuestionnaire />} />
        <Route path="/asset/:user_id/allocation" element={<FinancialAdviserAssetAllocation />} />
        <Route path="/logout" element={<FinancialAdviserLogout />} /> {/* Logout Route */}
      </Routes>
      <FinancialAdviserFooter />
    </>
  ) : (
    <Navigate to="/financial-adviser/login" replace />
  );
};

export default FinancialAdviserRoute;
