import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-dark text-light text-center py-3 mt-5">
      <div className="container">
        <p className="mb-0">© {new Date().getFullYear()} Financial Adviser. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
