import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const SummaryForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id");
  const token = queryParams.get("token");
  
  // ✅ Extract assessment results from location.state
  const assessmentResult = location.state?.result || "No summary available.";

  return (
    <div className="container mt-5 mb-5">
      <div className="card shadow-lg mt-3">
        <div className="card-body">
          <h3 className="card-title text-center mb-4">Summary</h3>

          {/* ✅ Display the assessment result */}
          <pre>{assessmentResult}</pre>

          {/* ✅ Button to go to the next step */}
          <div className="text-center mt-4">
            {/* <button 
              className="btn btn-primary" 
              onClick={() => navigate(`/steps?step=4&user_id=${userId}&token=${token}`)}
            >
              Next
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryForm;
