import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this is set in .env file
  headers: { "Content-Type": "application/json" },
});

export const LoginUser = async (userData) => {
  try {
    const response = await api.post("/financialadviser/login", userData); // Pass userData here
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message; // Handle error properly
  }
};
