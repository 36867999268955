import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { CreateAssetsAllocation } from "../../Settings/API/AssetAllocation/Create";

const AssetAllocationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("user_id"); // Get user_id from URL

  const [formData, setFormData] = useState({
    financial_adviser: "",
    risk_tolerance: "",
    investment_objective: "",
    expected_return: 0,
    investment_time_horizon: "",
    liquidity_needs: "",
    large_cap_stocks: 0,
    mid_cap_stocks: 0,
    small_cap_stocks: 0,
    international_stocks: 0,
    derivatives: 0,
    reits: 0,
    cryptocurrency: 0,
    sector_specific_funds: 0,
    dividend_stocks: 0,
    investment_notes: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); 
  const [submitted, setSubmitted] = useState(false);


  useEffect(() => {
    if (!userId) {
      setError("User ID is missing. Please check the URL.");
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name.includes("stocks") || name === "expected_return" ? Math.max(0, Number(value)) : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userId) {
      setError("User ID is required.");
      return;
    }

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const payload = { ...formData, user_id: userId }; // Include user_id in the JSON body
      const response = await CreateAssetsAllocation(payload);

      if(response.status == 'success' && response.status_code == 200)
      {
        setSubmitted(true);
        setSuccessMessage(response.message);
      }
    } catch (err) {
      setError("Error submitting asset allocation. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="card shadow-lg mt-3">
        <div className="card-body">
          <h3 className="card-title text-center mb-4">Asset Allocation</h3>

          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}

          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Financial Adviser</label>
              <input type="text" className="form-control" name="financial_adviser" value={formData.financial_adviser} onChange={handleChange} />
            </div>

            <div className="mb-3">
              <label className="form-label">Risk Tolerance</label>
              <select className="form-control" name="risk_tolerance" value={formData.risk_tolerance} onChange={handleChange} required>
                <option value="">Select</option>
                <option value="Low">Low</option>
                <option value="Moderate">Moderate</option>
                <option value="High">High</option>
              </select>
            </div>

            <div className="mb-3">
              <label className="form-label">Investment Objective</label>
              <input type="text" className="form-control" name="investment_objective" value={formData.investment_objective} onChange={handleChange} />
            </div>

            <div className="mb-3">
              <label className="form-label">Expected Return (%)</label>
              <input type="number" className="form-control" name="expected_return" value={formData.expected_return} onChange={handleChange} min="0" />
            </div>

            <div className="mb-3">
              <label className="form-label">Investment Time Horizon</label>
              <input type="text" className="form-control" name="investment_time_horizon" value={formData.investment_time_horizon} onChange={handleChange} />
            </div>

            <div className="mb-3">
              <label className="form-label">Liquidity Needs</label>
              <input type="text" className="form-control" name="liquidity_needs" value={formData.liquidity_needs} onChange={handleChange} />
            </div>

            {[
              "large_cap_stocks",
              "mid_cap_stocks",
              "small_cap_stocks",
              "international_stocks",
              "derivatives",
              "reits",
              "cryptocurrency",
              "sector_specific_funds",
              "dividend_stocks",
            ].map((field) => (
              <div className="mb-3" key={field}>
                <label className="form-label">{field.replace(/_/g, " ").toUpperCase()}</label>
                <input type="number" className="form-control" name={field} value={formData[field]} onChange={handleChange} min="0" />
              </div>
            ))}

            <div className="mb-3">
              <label className="form-label">Investment Notes</label>
              <textarea className="form-control" name="investment_notes" value={formData.investment_notes} onChange={handleChange} rows="3"></textarea>
            </div>

            {!submitted && (
              <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AssetAllocationForm;
