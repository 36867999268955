import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { registerUser } from "../../Settings/API/Register";
import { ProfileUpdateByUserId } from "../../Settings/API/Profileudpatebyuserid";

const UserInfoForm = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    gender: "",
    password: "",
    // pan_number: "",
    dob: "",
    // nationality: "",
    // profile_image: null,
    address: "",
    country: "",
    pincode: "",
    employment_status: "",
    avg_investment: "",
    secondary_language: "",
    earning: "",
    // apartment: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState(null);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setFormData((prevData) => ({
        ...prevData,
        ...storedUser.userData,
        password: "", // Don't pre-fill password for security
        profile_image: prevData.profile_image, // Keep profile image intact
      }));
    }
  }, []);

  // ✅ Handle Input Change
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
  
    setFormData((prev) => ({
      ...prev,
      [name]: type === "file" ? files[0] : name === "dob" ? formatDate(value) : value,
    }));
  };
  
  // Function to format the date as YYYY-MM-DD
  const formatDate = (date) => {
    if (!date) return "";
    const parsedDate = new Date(date);
    return parsedDate.toISOString().split("T")[0]; // Converts to YYYY-MM-DD
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      submitForm();
    } 
  };
  
  const validate = () => {
    let newErrors = {};
  
    if (!formData.first_name.trim()) newErrors.first_name = "First name is required";
    if (!formData.last_name.trim()) newErrors.last_name = "Last name is required";
    if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Valid email is required";
    }
    if (!formData.phone_number.trim()) newErrors.phone_number = "Phone number is required";
    if (!formData.gender.trim()) newErrors.gender = "Gender is required";
    if (!formData.password.trim() || formData.password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long";
    }
    if (!formData.dob.trim()) {
      newErrors.dob = "Date of birth is required";
    } else {
      const dobDate = new Date(formData.dob);
      const today = new Date();
      const age = today.getFullYear() - dobDate.getFullYear();
      if (age < 18) newErrors.dob = "You must be at least 18 years old";
    }
    if (!formData.country.trim()) newErrors.country = "Country is required";
    if (!formData.address.trim()) newErrors.address = "Street address is required";
    if (!formData.pincode.trim() || !/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Valid 6-digit PIN code is required";
    }
    if (!formData.employment_status.trim()) newErrors.employment_status = "Employment status is required";
    if (!formData.avg_investment.trim()) newErrors.avg_investment = "Average investment is required";
    if (!formData.secondary_language.trim()) newErrors.secondary_language = "Secondary language selection is required";
    if (!formData.earning.trim()) newErrors.earning = "Earning is required";
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  
  const submitForm = async () => {
    try {
      setLoading(true);
      setApiMessage(null);

  
      // Step 1: Register User
      const registerPayload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        phone_number: formData.phone_number,
        gender: formData.gender,
        password: formData.password,
      };
  
      const registerResponse = await registerUser(registerPayload);
  
      if (registerResponse.status === "success" && registerResponse.status_code === 200) {
        const userId = registerResponse.results.id; // ✅ Corrected ID extraction
        const token = registerResponse.results.token; // ✅ Corrected ID extraction
  
        // Step 2: Profile Update
        const profilePayload = new FormData();

        // ✅ Append required fields
        profilePayload.append("user_id", userId);
        profilePayload.append("first_name", formData.first_name || "");
        profilePayload.append("last_name", formData.last_name || "");
        profilePayload.append("phone_number", formData.phone_number || "");
        // profilePayload.append("nationality", formData.nationality || "");
        profilePayload.append("phone_number", formData.phone_number || "");
        profilePayload.append("address", formData.address || "");
        profilePayload.append("country", formData.country || "");
        profilePayload.append("pincode", formData.pincode || "");
        profilePayload.append("employment_status", formData.employment_status || "");
        profilePayload.append("gender", formData.gender || "");
        profilePayload.append("terms_condition", formData.terms_condition || "true");
        profilePayload.append("privacy_policy", formData.privacy_policy || "true");
        profilePayload.append("earning", formData.earning || "");
        profilePayload.append("avg_investment", formData.avg_investment || "]");
        profilePayload.append("secondary_language", formData.secondary_language || "");
        profilePayload.append("dob", formData.dob || "");
        // profilePayload.append("pan_number", formData.pan_number || "");
        profilePayload.append("apartment", formData.apartment || "");
        const profileResponse = await ProfileUpdateByUserId(userId,profilePayload, token);

        if (profileResponse.status === "success" && profileResponse.status_code === 200) {
          setApiMessage({ type: "success", text: profileResponse.message });
          setTimeout(() => {
            navigate(`/steps?step=2&user_id=${userId}&token=${token}`);
          }, 1000);
        }
        
      } else if (registerResponse.status === "error" && registerResponse.status_code === 400) {
        if (registerResponse.message.includes("Email is already registered")) {
          setApiMessage({ type: "error", text: "This email is already registered. Please Sign In." });
        } else {
          setApiMessage({ type: "error", text: registerResponse.message || "Something went wrong. Please try again." });
        }
      }
    } catch (error) {
      setApiMessage({ type: "error", text: error.message || "Something went wrong. Please try again." });
    } finally {
      setLoading(false);
    }
  };
  
  
  

  return (
    <div className="container mt-5 mb-5">
      <div className="card shadow-lg mt-3">
        <div className="card-body">
          <h3 className="card-title text-center mb-4">User Info</h3>
          {apiMessage && (
            <div className={`alert alert-${apiMessage.type === "success" ? "success" : "danger"}`}>
              {apiMessage.text}
            </div>
          )}
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row">
              {/* Gender */}
              <div className="col-sm-2 mb-3">
                <label className="form-label">Gender</label>
                <select className="form-select" name="gender" value={formData.gender} onChange={handleChange} disabled={loading}>
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
                {errors.gender && <small className="text-danger">{errors.gender}</small>}
              </div>
              {/* First Name */}
              <div className="col-sm-4 mb-3">
                <label className="form-label">First Name</label>
                <input type="text" className="form-control" name="first_name" value={formData.first_name} onChange={handleChange} />
                {errors.first_name && <small className="text-danger">{errors.first_name}</small>}
              </div>

              {/* Last Name */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Last Name</label>
                <input type="text" className="form-control" name="last_name" value={formData.last_name} onChange={handleChange} />
                {errors.last_name && <small className="text-danger">{errors.last_name}</small>}
              </div>

              {/* Email */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Email</label>
                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} />
                {errors.email && <small className="text-danger">{errors.email}</small>}
              </div>
               {/* Password */}
               <div className="col-sm-6 mb-3">
                <label className="form-label">Password</label>
                <input type="password" className="form-control" name="password" value={formData.password} onChange={handleChange} disabled={loading} />
                {errors.password && <small className="text-danger">{errors.password}</small>}
              </div>

              {/* Phone Number */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Phone Number</label>
                <input type="tel" className="form-control" name="phone_number" value={formData.phone_number} onChange={handleChange} disabled={loading} />
                {errors.phone_number && <small className="text-danger">{errors.phone_number}</small>}
              </div>

              {/* PAN Number */}
              {/* <div className="col-sm-6 mb-3">
                <label className="form-label">PAN Number</label>
                <input type="text" className="form-control" name="pan_number" value={formData.pan_number} onChange={handleChange} />
                {errors.pan_number && <small className="text-danger">{errors.pan_number}</small>}
              </div> */}

              {/* DOB */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Date of Birth</label>
                <input type="date" className="form-control" name="dob" value={formData.dob} pattern="\d{4}-\d{2}-\d{2}" onChange={handleChange} />
                {errors.dob && <small className="text-danger">{errors.dob}</small>}
              </div>  

              {/* Address */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Address</label>
                <input type="text" className="form-control" name="address" value={formData.address} onChange={handleChange} />
                {errors.address && <small className="text-danger">{errors.address}</small>}
              </div>

              {/* Employment Status */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Employment Status</label>
                <select className="form-select" name="employment_status" value={formData.employment_status} onChange={handleChange}>
                  <option value="">Select Employment Status</option>
                  <option value="Salaried">Salaried</option>
                  <option value="Business">Business</option>
                </select>
                {errors.employment_status && <small className="text-danger">{errors.employment_status}</small>}
              </div>

              {/* Country */}
              <div className="col-sm-6 mb-3">
                <label className="form-label">Country</label>
                <input type="text" className="form-control" name="country" value={formData.country} onChange={handleChange} />
                {errors.country && <small className="text-danger">{errors.country}</small>}
              </div>

                {/* Pincode */}
                <div className="col-sm-6 mb-3">
                  <label className="form-label">Pincode</label>
                  <input type="number" className="form-control" name="pincode" value={formData.pincode} onChange={handleChange} />
                  {errors.pincode && <small className="text-danger">{errors.pincode}</small>}
                </div>

                {/* Average Investment */}
                <div className="col-sm-6 mb-3">
                  <label className="form-label">Average Investment</label>
                  <input type="number" className="form-control" name="avg_investment" value={formData.avg_investment} onChange={handleChange} />
                  {errors.avg_investment && <small className="text-danger">{errors.avg_investment}</small>}
                </div>

                {/* Secondary Language */}
                <div className="col-sm-6 mb-3">
                  <label className="form-label">Secondary Language</label>
                  <input type="text" className="form-control" name="secondary_language" value={formData.secondary_language} onChange={handleChange} />
                  {errors.secondary_language && <small className="text-danger">{errors.secondary_language}</small>}
                </div>

                {/* Earning */}
                <div className="col-sm-6 mb-3">
                  <label className="form-label">Earning</label>
                  <input type="number" className="form-control" name="earning" value={formData.earning} onChange={handleChange} />
                  {errors.earning && <small className="text-danger">{errors.earning}</small>}
                </div>

                {/* Apartment */}
                {/* <div className="col-sm-6 mb-3">
                  <label className="form-label">Apartment</label>
                  <input type="text" className="form-control" name="apartment" value={formData.apartment} onChange={handleChange} />
                  {errors.apartment && <small className="text-danger">{errors.apartment}</small>}
                </div> */}

                {/* Nationality */}
                {/* <div className="col-sm-6 mb-3">
                  <label className="form-label">Nationality</label>
                  <input type="text" className="form-control" name="nationality" value={formData.nationality} onChange={handleChange} />
                  {errors.nationality && <small className="text-danger">{errors.nationality}</small>}
                </div> */}

                {/* Profile Image (Optional) */}
                {/* <div className="col-sm-6 mb-3">
                  <label className="form-label">Profile Image</label>
                  <input type="file" className="form-control" name="profile_image" onChange={handleChange} />
                  {errors.profile_image && <small className="text-danger">{errors.profile_image}</small>}
                </div> */}
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-primary px-4">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserInfoForm;
