import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [fullName, setFullName] = useState("User");

  useEffect(() => {
    const storedData = localStorage.getItem('AuthFinancialAdviser');
    if (storedData) {
      try {
        const userData = JSON.parse(storedData);
        const firstName = userData.first_name || "";
        const lastName = userData.last_name || "";
        setFullName(`${firstName} ${lastName}`.trim());
      } catch (error) {
        console.error("Error parsing user data:", error);
      }
    }
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        {/* Brand Name */}
        <Link className="navbar-brand" to="/financial-adviser">
          <i className="fa fa-chart-line me-2"></i> Financial Adviser
        </Link>

        {/* Navbar Toggler for Mobile */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Links */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {/* User Dropdown */}
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                to="#"
                id="userDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa fa-user-circle me-2"></i> {fullName}
              </Link>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">
                <li className='p-1'>
                  <Link className="dropdown-item" to="/financial-adviser/userlist">
                    <i className="fa fa-users me-2"></i> All Users
                  </Link>
                </li>
                <li className='p-1'> 
                  <Link className="dropdown-item" to="/financial-adviser/profile">
                    <i className="fa fa-user me-2"></i> Profile
                  </Link>
                </li>
                <li  className='p-1'>
                  <Link className="dropdown-item text-danger" to="/financial-adviser/logout">
                    <i className="fa fa-sign-out me-2"></i> Logout
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
