import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this is set in .env file
  headers: { "Content-Type": "application/json" },
});

// Function to Fetch Risk Assessment by User ID
export const RiskAssesmentbyUserId = async (id) => {
  try {
    const response = await api.get(`/riskassesment/${id}`);
    return response.data; // Return success response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return {
        status: "error",
        status_code: error.response?.status || 500,
        message: error.response?.data?.message || "Something went wrong",
      };
    }
    return {
      status: "error",
      status_code: 500,
      message: "An unexpected error occurred",
    };
  }
};
