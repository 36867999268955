import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import { RiskAssesmentbyUserId } from "../Settings/API/RiskAssesment/RiskAssesmentbyUserId";

const FinancialAdviserUserRiskQuestionnaire = () => {
  const { id } = useParams();
  const [questionnaire, setQuestionnaire] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      setLoading(true);
      setError("");
      try {
        const response = await RiskAssesmentbyUserId(id);
        console.log("API Response:", response);
    
        if (response.status === "success" && response.status_code === 200) {
          console.log("Fetched Data:", response.results);
          setQuestionnaire(response.results);
        } else if (response.status === "error" && response.status_code === 404) {
          setError(response.message);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    
    fetchQuestionnaire();
  }, [id]);

  if (loading) {
    return <p className="text-center">Loading risk questionnaire...</p>;
  }

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-6">
          <h2><i className="fa fa-file me-2"></i> Risk Question</h2>
        </div>
        <div className="col-md-6 text-end">
          <Link
            className="btn btn-primary btn-sm me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Profile"
            to={`/financial-adviser/user/${id}`}
          >
            <i className="fa fa-user"></i> View
          </Link>
          <Link
            className="btn btn-primary btn-sm me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Back"
            to={`/financial-adviser/userlist`}
          >
            Back
          </Link>
        </div>
      </div>

      {error && (
        <div className="alert alert-danger text-center mt-5">{error}</div>
      )}

      {questionnaire && (
        <div className="row mt-3">
          <div className="col-md-6">
            {questionnaire.conversation_history && questionnaire.conversation_history.length > 0 ? (
              <div className="accordion" id="riskQuestionnaireAccordion">
                {questionnaire.conversation_history.map((response, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={`heading${index}`}>
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${index}`}
                        aria-expanded="true"
                        aria-controls={`collapse${index}`}
                      >
                        <strong>Q{index + 1}:</strong> {response.question}
                      </button>
                    </h2>
                    <div
                      id={`collapse${index}`}
                      className="accordion-collapse collapse"
                      aria-labelledby={`heading${index}`}
                      data-bs-parent="#riskQuestionnaireAccordion"
                    >
                      <div className="accordion-body">
                        {response.hint && <p className="text-muted"><strong>Hint:</strong> {response.hint}</p>}
                        <p><strong>Answer:</strong> {response.response}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              !error && <p className="text-center">No risk assessment data available.</p>
            )}
          </div>
          <div className="col-md-6">
            {questionnaire.assesment_summary && (
              <div className="card p-3">
                <h4>Assessment Summary</h4>
                <pre className="bg-light p-3 rounded">{questionnaire.assesment_summary}</pre>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FinancialAdviserUserRiskQuestionnaire;