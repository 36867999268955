import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

const FinancialAdviserLogout = () => {
  useEffect(() => {
    // Clear authentication data
    localStorage.removeItem('AuthFinancialAdviser');
    localStorage.removeItem('isAuthenticatedFinancialAdviser');
  }, []);

  return <Navigate to="/financial-adviser/login?message=logged-out" replace />;
};

export default FinancialAdviserLogout;
