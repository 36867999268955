import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import UserInfoForm from "../components/Steps/UserInfoForm";
import QuestionAndAnswerForm from "../components/Steps/QuestionAndAnswerForm";
import SummaryForm from "../components/Steps/SummaryForm";
import AssetAllocationForm from "../components/Steps/AssetAllocationForm";

const Steps = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const steps = [
    { step: 1, label: "User Info", path: "/steps?step=1" },
    { step: 2, label: "Questions", path: "/steps?step=2" },
    { step: 3, label: "Summary", path: "/steps?step=3" },
    // { step: 4, label: "Asset Allocation", path: "/steps?step=4" },
  ];

  // Extract step number from URL or default to step 1
  const queryParams = new URLSearchParams(location.search);
  const currentStep = parseInt(queryParams.get("step")) || 1;

  // Redirect to step 1 if no step is set in the URL
  useEffect(() => {
    if (!queryParams.get("step")) {
      navigate("/steps?step=1", { replace: true });
    }
  }, [navigate, queryParams]);

  return (
    <div className="container mt-4">
      {/* Steps Navigation */}
      <div className="card shadow-lg">
        <div className="card-body text-center">
          <div className="d-flex justify-content-between">
            {steps.map(({ step, label, path }) => (
              <button
                key={step}
                to={path}
                className={`btn ${currentStep === step ? "btn-primary fw-bold" : "btn-outline-secondary"} px-4 py-2`}
              >
                {label}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Render UserInfoForm if Step 1 */}
      {currentStep === 1 && <UserInfoForm />}
      {currentStep === 2 && <QuestionAndAnswerForm />}
      {currentStep === 3 && <SummaryForm />}
      {currentStep === 4 && <AssetAllocationForm />}
    </div>
  );
};

export default Steps;
