import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this is set in .env file
  headers: { "Content-Type": "application/json" },
});

// Function to Fetch User List (No Auth Required)
export const Userlist = async () => {
  try {
    const response = await api.get("/users/users");
    return response.data; // Return user data
  } catch (error) {
    console.error("Error fetching users:", error); // Log the error
    return { success: false, message: error.response?.data || "Something went wrong!" };
  }
};
