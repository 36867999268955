import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { CreateAssetsAllocation } from "../Settings/API/AssetAllocation/Create";
import { UpdateAssetsAllocation } from "../Settings/API/AssetAllocation/Update";
import { GetAssetsAllocation } from "../Settings/API/AssetAllocation/Get";

const FinancialAdviserAssetAllocation = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const [assetAllocation, setAssetAllocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [financialAdviserId, setFinancialAdviserId] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const [formData, setFormData] = useState({
    risk_tolerance: "",
    investment_objective: "",
    expected_return: 0,
    investment_time_horizon: "",
    liquidity_needs: "",
    large_cap_stocks: 0,
    mid_cap_stocks: 0,
    small_cap_stocks: 0,
    international_stocks: 0,
    derivatives: 0,
    reits: 0,
    cryptocurrency: 0,
    sector_specific_funds: 0,
    dividend_stocks: 0,
    investment_notes: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("AuthFinancialAdviser");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        setFinancialAdviserId(parsedData.id);
      } catch (err) {
        console.error("Error parsing localStorage data", err);
        setError("Invalid financial adviser data.");
      }
    } else {
      setError("Financial Adviser ID is missing.");
    }

    if (!user_id) {
      setError("User ID is required.");
    }

    const fetchAssetAllocation = async () => {
      try {
          const response = await GetAssetsAllocation(user_id);
        if (response?.results?.length > 0) {
          // Find the asset allocation for the given user_id
          const userAsset = response.results.find(item => item.user_id === user_id);
          
          if (userAsset) {
            setIsUpdate(true);
            setAssetAllocation(userAsset);  // Save the correct asset allocation
            setFormData(userAsset);         // Populate form fields
          } else {
            setIsUpdate(false);
          }
        } else {
          setIsUpdate(false);
        }
      } catch (err) {
        if(err?.status === 'error' && err?.status_code === 400)
          {
            setError(err?.message);
          }
  
      }
    };
    

    fetchAssetAllocation();
  }, [user_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name.includes("stocks") || name === "expected_return" ? Math.max(0, Number(value)) : value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      if (isUpdate) {
        const payload = { ...formData, financial_adviser: financialAdviserId, user_id: user_id };
        const response = await UpdateAssetsAllocation(payload);
        if (response.status === "success" && response.status_code === 200) {
          setSuccessMessage(response.message);
          setIsUpdate(true);
        }
      } else {
        const payload = { ...formData, financial_adviser: financialAdviserId, user_id: user_id };
        const response = await CreateAssetsAllocation(payload);

        if (response.status === "success" && response.status_code === 200) {
          setAssetAllocation(response.results);  // Save the correct asset allocation
          setFormData(response.results);   
          setSuccessMessage(response.message);
          setIsUpdate(true);
        }
      }
    } catch (err) {
      setError("Error submitting asset allocation. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-sm-6">
          <h2><i className="fa fa-user me-2"></i> Asset Allocation</h2>
        </div>
        <div className="col-sm-6 text-end">
          <Link
            className="btn btn-primary btn-sm me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Back"
            to={`/financial-adviser/userlist`} // Corrected template string usage
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card shadow-lg mt-3">
        <div className="card-body">
          {error && <div className="alert alert-danger">{error}</div>}
          {successMessage && <div className="alert alert-success">{successMessage}</div>}

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Risk Tolerance</label>
                  <select className="form-control" name="risk_tolerance" value={formData.risk_tolerance} onChange={handleChange} required>
                    <option value="">Select</option>
                    <option value="Low">Low</option>
                    <option value="Moderate">Moderate</option>
                    <option value="High">High</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Investment Objective</label>
                  <input type="text" className="form-control" name="investment_objective" value={formData.investment_objective} onChange={handleChange} />
                </div>
              </div>

              <div className="col-md-6">
                <div className="mb-3">
                  <label className="form-label">Expected Return (%)</label>
                  <input type="number" className="form-control" name="expected_return" value={formData.expected_return} onChange={handleChange} min="0" />
                </div>
                <div className="mb-3">
                  <label className="form-label">Investment Time Horizon</label>
                  <input type="text" className="form-control" name="investment_time_horizon" value={formData.investment_time_horizon} onChange={handleChange} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Liquidity Needs</label>
                  <input type="text" className="form-control" name="liquidity_needs" value={formData.liquidity_needs} onChange={handleChange} />
                </div>
              </div>
            </div>

            <div className="row">
              {[
                "large_cap_stocks",
                "mid_cap_stocks",
                "small_cap_stocks",
                "international_stocks",
                "derivatives",
                "reits",
                "cryptocurrency",
                "sector_specific_funds",
                "dividend_stocks",
              ].map((field, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <label className="form-label">{field.replace(/_/g, " ").toUpperCase()}</label>
                  <input type="number" className="form-control" name={field} value={formData[field]} onChange={handleChange} min="0" />
                </div>
              ))}
            </div>

            <div className="mb-3">
              <label className="form-label">Investment Notes</label>
              <textarea className="form-control" name="investment_notes" value={formData.investment_notes} onChange={handleChange} rows="3"></textarea>
            </div>

            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
              {loading ? "Processing..." : isUpdate ? "Update" : "Save"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FinancialAdviserAssetAllocation;
