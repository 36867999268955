import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL; // ✅ Ensure env variable is correctly defined

export const RiskAssesment = async (userId, responseText = null, token) => {
  try {
    const payload = { user_id: userId };
    if (responseText) payload.response = responseText;

    const apiUrl = `${API_URL}/riskassesment/`; // Ensure correct URL
    const response = await axios.post(apiUrl, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      maxRedirects: 0, // Prevents Axios from automatically following redirects
    });

    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    return error.response?.data || { message: "Something went wrong" };
  }
};
