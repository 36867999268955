import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this is correct
  headers: { "Content-Type": "application/json" },
});

export const registerUser = async (userData) => {
  try {
    const response = await api.post("/users/register", { ...userData, sign_up_type: "autoverified" });
    return response.data;
  } catch (error) {
    throw error;
  }
};
