import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // ✅ Ensure this is correctly set in .env
});

// ✅ Function to update user profile
export const ProfileUpdateByUserId = async (userId, userData, token) => {
  try {
    const isFormData = userData instanceof FormData; // ✅ Check if FormData is used
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    };

    const response = await api.patch(`users/update/${userId}`, userData, { headers });

    return response.data;
  } catch (error) {
    console.error("❌ Profile Update Error:", error.response?.data || error);
    throw error.response?.data || { message: "Something went wrong" };
  }
};
