import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { UserbyId } from '../Settings/API/User/UserbyId';

const FinancialAdviserUserProfile = () => {
  const { id } = useParams(); // Get user ID from URL
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await UserbyId(id); // Pass the user ID to API
        if (response.status === "success" && response.status_code === 200 && response.results) {
          setUser(response.results); // Store user data in state
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUser();
  }, [id]);

  if (!user) {
    return <p className="text-center">Loading user profile...</p>;
  }

  return (
    <div className="container mt-4 vh-100">
      <h2 className="text-center">

      </h2>
      <div className="row">
        <div className="col-sm-6">
          <h2><i className="fa fa-user me-2"></i> User Profile</h2>
        </div>
        <div className="col-sm-6 text-end">
          <Link
            className="btn btn-primary btn-sm me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Risk Questionnaire Response"
            to={`/financial-adviser/user/${id}/risk-questionnaire`} // Assuming this is the correct route
          >
            <i className="fa fa-file"></i> View
          </Link>

          <Link
            className="btn btn-primary btn-sm me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Back"
            to={`/financial-adviser/userlist`} // Corrected template string usage
          >
            Back
          </Link>
        </div>
      </div>
      <div className="card p-3 shadow-sm">
        <div className="d-flex align-items-center">
          {/* Profile Image */}
          <img
            src={user.profile_image
              ? `${process.env.REACT_APP_API_URL}/${user.profile_image}`
              : "/profileimg.png"} // Default profile image
            alt="Profile"
            className="rounded-circle border me-3"
            width="80"
            height="80"
          />
          <div>
            <h4 className="mb-1">{user.first_name || "N/A"} {user.last_name || "N/A"}</h4>
            <p className="text-muted m-0">{user.email || "N/A"}</p>
          </div>
        </div>
        <hr />

        {/* Personal Details */}
        <div className="row">
          <div className="col-md-6">
            <p><strong>Phone:</strong> {user.phone_number || "N/A"}</p>
            <p><strong>Date of Birth:</strong> {user.dob ? new Date(user.dob).toLocaleDateString() : "N/A"}</p>
            <p><strong>Gender:</strong> {user.gender || "N/A"}</p>
            <p><strong>Nationality:</strong> {user.nationality || "N/A"}</p>
            <p><strong>Employment Status:</strong> {user.employment_status || "N/A"}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Country:</strong> {user.country || "N/A"}</p>
            <p><strong>Address:</strong> {user.address || "N/A"}</p>
            <p><strong>Pincode:</strong> {user.pincode || "N/A"}</p>
            <p><strong>Secondary Language:</strong> {user.secondary_language || "N/A"}</p>
          </div>
        </div>
        <hr />

        {/* Financial Details */}
        <div className="row">
          <div className="col-md-6">
            <p><strong>Average Investment:</strong> ₹{user.avg_investment ? user.avg_investment.toLocaleString() : "N/A"}</p>
            <p><strong>Earning:</strong> ₹{user.earning ? user.earning.toLocaleString() : "N/A"}</p>
          </div>
          <div className="col-md-6">
            <p><strong>Profile Completed:</strong> {user.profile_complete ? "✅ Yes" : "❌ No"}</p>
            <p><strong>Created At:</strong> {new Date(user.created_at).toLocaleString()}</p>
            <p><strong>Updated At:</strong> {new Date(user.updated_at).toLocaleString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialAdviserUserProfile;
