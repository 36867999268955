import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { LoginUser } from '../../Settings/API/FinancialAdviser/login'; // Ensure correct path

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      const storedData = localStorage.getItem('AuthFinancialAdviser');
      const isAuthenticated = localStorage.getItem('isAuthenticatedFinancialAdviser');

      // Check if logout message exists in the URL
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get('message') === 'logged-out') {
        setSuccess('You have been logged out.');
      }

      if (storedData && isAuthenticated === 'true') {
        const AuthUser = JSON.parse(storedData);
        const token = AuthUser?.token; // Ensure token exists

        if (token) {
          navigate('/financial-adviser');
        }
      }
    } catch (err) {
      setError('Session expired. Please log in again.');
      localStorage.removeItem('isAuthenticatedFinancialAdviser');
      localStorage.removeItem('AuthFinancialAdviser');
    }
  }, [location, navigate]); 


  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await LoginUser({ username, password });

      if (response?.status === 'success' && response?.status_code === 200 && response?.status_message === "OK") {
        setSuccess(response?.message);

        const token = response?.results?.token; // Ensure token exists
        localStorage.setItem('isAuthenticatedFinancialAdviser', 'true');
        localStorage.setItem('AuthFinancialAdviser', JSON.stringify(response?.results));

        if (token) {
          navigate('/financial-adviser');
        } else {
          navigate('/financial-adviser/login'); // Fallback if no token
        }
      } else {
        setError(response?.message || 'Invalid credentials');
      }
    } catch (err) {
      setError(err?.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="card p-4 shadow-lg" style={{ width: '400px' }}>
        <h3 className="text-center mb-3">Financial Adviser Login</h3>

        {error && (
          <div className="alert alert-danger text-center">
            {error}
          </div>
        )}
        {success && (
          <div className="alert alert-success text-center">
            {success}
          </div>
        )}

        <form onSubmit={handleLogin}>
          <div className="mb-3">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
