import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Ensure this is set in .env file
  headers: { "Content-Type": "application/json" },
});

// Function to Fetch User by ID (No Auth Required)
export const UserbyId = async (id) => {
  try {
    const response = await api.get(`/users/users/${id}`); // Corrected URL to use dynamic ID
    return response.data; // Return user data
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    return { success: false, message: error.response?.data || "Something went wrong!" };
  }
};
