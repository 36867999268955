import React from 'react';

const FinancialAdviserDashboard = () => {
  return (
    <div className='vh-100'>
      <h1>Financial Adviser Dashboard</h1>
      <p>Welcome to the financial adviser dashboard.</p>
    </div>
  );
};

export default FinancialAdviserDashboard;
